
body {
  background-color: #000;
  font-family: "futura-pt", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.6;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.2;
  margin: 0 0 0.4em 0;
}

p {
  margin: 0 0 0.8em;
}

a:link, a:visited {
  color: #eee;
  text-decoration: none;
  transition: color 150ms ease;
}

a:active, a:focus, a:hover {
  color: shade(#eee, 25%);
}

ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

blockquote {
  background-color: #eee;
  font-style: italic;
  max-height: 250px;
  overflow: auto;
  padding: 0 1.6em;
}

cite {
  font-style: normal;
}

cite:before {
  content: "– ";
}

.prompt {
  color: #999;
  font-size: 0.75em;
}

.hidden {
  display: none;
}

.unhidden {
  display: block;
}

/* ////////////////// */
/*      HOME PAGE     */
/* ////////////////// */

.home {
  padding: 1.6em 0;
  text-align: center;
  font-size: 1.25em;
}

.home h1 {
  color: #ff9933;
}

.home p {
  color: #eee;
  margin: 1.6em auto;
}

.home p a {
  color: #ff9933;
}

.mural-menu {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(11, minmax(160px, 1fr));
  width: 100vw;
  overflow-x: scroll;
}

.mural-menu a img {
  max-width: 100%;
}

.mural-menu h2 {
  color: #ff9933;
  font-size: 0.75em;
}

.home-info p {
  font-size: 1em;
  color: #b9b9b9;
}

/*large display*/
@media only screen and (min-width: 800px) {
  .home-msm-link {
    position: absolute;
    top: 5px;
    left: 5px;    
  }
}
/* ///////////////////// */
/* SHARED LAYOUT ELMENTS */
/* ///////////////////// */

/*small display*/
.wrapper {
  left: 0;
  /* position: fixed; */
  display: grid;
  touch-action: pan-y;
  grid-template-areas:
   "msm-link site-title"
   "panel-nav panel-nav"
   "panel-title panel-title"
   "content-area content-area";
}

/*large display*/
@media only screen and (min-width: 800px)   {
  .wrapper {
    left: 0;
    position: fixed;
    grid-template-columns: 220px auto 220px;
    grid-template-areas:
      "msm-link site-title panel-nav"
      "panel-title panel-title panel-title"
      "content-area content-area content-area";
  }
}

.msm-link {
  grid-area: msm-link;
}

.msm-logo {
  background-color: #c7c7c7;
  height: auto;
  padding: 4px;
  width: 80px;
}

.site-title {
  grid-area: site-title;
  justify-self: center;
}

.site-title h3{
  padding: 0;
  margin: 0 auto;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: .75em;
}

.site-title a:link, .site-title a:visited {
  color: #eee;
  text-decoration: none;
}

.site-title a:hover {
  color: #ff9933;
  text-decoration: underline;
}

.panel-nav {
  grid-area: panel-nav;
  text-align: center;
}

.panel-nav img {
  margin-right: 2px;
  max-width: 7%;
}

img.panel-nav-selected {
  border: 2px solid #ff9933;
}

.msm-link, .site-title, .panel-nav {
  color: #eee;
  margin: .5em;
}

.panel-title {
  color: #ff9933;
  grid-area: panel-title;
  padding: 0 1em;
  text-align: center;
}

.prev-panel {
  grid-area: prev-panel;
}

.next-panel {
  grid-area: next-panel;
}

.prev-panel, .next-panel {/* for small layout */
  background-color: #000;
  position: relative;
}

.prev-panel img, .next-panel img {/* for small layout */
  max-width: 100%;
}

img.arrow {
  position: absolute;
  top: 25%;
  max-width: 25px;
}

.next-panel a img {
  right: 4px;
}

.prev-panel a img {
  left: 4px;
}

.panel-title h1 {
  font-size: 2.25em;
  font-weight: 600;
  margin: 0 1em 0.25em 1em;
}

.panel-title h1 a {
  margin: 0;
  color: #ff9933;
  text-decoration: underline;
}

a.more-link {
  visibility: hidden;
}

/* ////////////////// */
/*  PANEL INTRO PAGE  */
/* ////////////////// */

.content-area {
  background-color: #fff;
  display: grid;
  grid-area: content-area;
  grid-template-columns: 8% auto 8%;  
  touch-action: pan-y;
  grid-template-areas: 
    "prev-panel current-panel next-panel";
}

.current-panel {
  color: #333;
  grid-area: current-panel;
  display: grid;
  grid-template-areas:
    "text"
    "panel-image"
    "article-buttons";
  overflow: auto;
}

/*large display*/
@media only screen and (min-width: 800px)   {
  .current-panel {
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas:
      "text panel-image article-buttons";
  }
}

.current-panel article {
  grid-area: text;
  padding: 1em;
}

.current-panel .panel-image svg {
  grid-area: panel-image;
}

/*large display*/
@media only screen and (min-width: 800px)   {
.current-panel .panel-image svg {
  grid-area: panel-image;
  height: 60vw;
  }
}

/* ////////////////// */
/*    ARTICLE PAGE    */
/* ////////////////// */

.current-panel-article {
  background-color: #fff;
  color: #333;
  display: grid;
  grid-gap: 1em;
  grid-area: current-panel;
  grid-template-areas: 
    "tabs"
    "article-body"
    "panel-image"
    "learn-more";
  padding: 0 1em;
}

/*large display*/
@media only screen and (min-width: 800px)   {
  .current-panel-article {
    grid-area: current-panel;
    grid-template-areas: 
      "tabs tabs tabs"
      "article-body panel-image learn-more";
    grid-template-columns: 30% auto 30%;
    grid-template-rows: 60px auto;
  }
}

.current-panel-article nav.tabs {
  grid-area: tabs;
  text-align: center;
}

nav.tabs ul {
  font-size: 0.75em;
  letter-spacing: 0.025em;
  text-transform: uppercase;
}

nav.tabs ul li {
  background-color: #ff9933;
  border: 1px solid #e67300;
  border-radius: 50px;
  display: inline-block;
  font-weight: bold;
  height: 50px;
  letter-spacing: 0.05em;
  line-height: 1.2;
  margin: 0px 0px 1em 0px;
  text-align: center;
  width: 100%;
}

nav.tabs ul li a:link, nav.tabs ul li a:visited {
  color: #fff;
  display: block;
  margin: 4.5%;
}

div.current-panel-article nav.tabs ul li {
  width: 300px;
  margin: 0.5em;
}

div.current-panel-article nav.tabs ul li a, div.current-panel-article nav.tabs ul li span {
  display: block;
  margin:  5%;
}

.tabs ul li a:hover {
  color: #444;
  text-decoration: underline;
}

.tabs ul li.selected-article {
  background-color: #444;
  border: 1px solid #999;
  color: #eee;
}

.current-panel-article article.article-body {
  grid-area: article-body;
}

.current-panel-article div.panel-image {
  grid-area: panel-image;
}

.current-panel-article nav.learn-more {
  grid-area: learn-more;
  padding: 0 1em;
}

.current-panel-article div.panel-image img {
  max-width: 100%;
  pointer-events: none;  
}

.current-panel .tabs {
  grid-area: article-buttons;
  padding: 1em;
}

article.article-body {
  background-color: #eee;
  height: 60vh;
  overflow-y: scroll;
  padding: .15em .5em .25em .5em;
}

.learn-more ul li {
  line-height: 1.2;
  margin-bottom: 1.6em;
  vertical-align: top;
}

.learn-more ul li:last-child {
  margin-right: 0px;
}

.learn-more ul li img {
  border: 1px solid #999;
  display: block;
  margin-bottom: .25em;
  max-width: 200px;
}

.learn-more ul li a {
    color: #999;
}

/* ////////////////// */
/*        POPS        */
/* ////////////////// */
.slimpop-wrapper {
  background-color: #fff;
  position: relative;
  padding: 3%;
  margin: auto;
}

.slimpop-wrapper h1 {
  color: #ff9933;
}

.slimpop-wrapper h4.subhead {
  color: #999;
  margin-bottom: 0;
}

.slimpop-wrapper p {
  color: #444;
  font-size: 1.025em;
}

.pop-close {
  float: right;
}

.pop-close a:link, .pop-close a:visited {
  color: #ff9933;
}

#slimpop-overlay {
  background: url(./assets/overlay.png) repeat;
  cursor: pointer;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 99;
}

/* small display */
#slimpop-container {
  margin: 0;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  z-index: 100;
}

/* large display */
@media only screen and (min-width: 800px)   {
  #slimpop-container {
    margin: 3% auto;
    max-height: 85%;
    overflow-y: auto;
    width: 75%;
    z-index: 100;
  }
} 

.pop-img {
  border-top: 6px solid #eee;
  /* border-bottom: 6px solid #eee; */
  margin-bottom: 0.8em;
  position: relative;
  text-align: center;
}

.pop-img img {
  border: 1px solid #eee;
  margin-top: 0.8em;
  max-height: 500px;
  max-width: 90%;
  pointer-events: none;
}

.pop-img p {
  color: #999;
  font-size: 0.75em !important;
  margin-top: 0;
}

audio.voices {
  width: 50%;
}

/* small display */
.prev-slide, .next-slide {
  position: absolute;
  top: 30%;
}

/* large display */
@media only screen and (min-width: 800px)  {
  .prev-slide, .next-slide {
    top: 180px;
  }
} 

.prev-slide {
  left: 4px;
}

.next-slide {
  right: 4px;
}

.slide-nav span.no-more {
  color: #aeaeae;
  cursor: default;
}

.slide-nav a:link, .slide-nav a:visited {
  color: #444;
}

.slide-nav a:hover {
  color: #b35900;
  text-decoration: underline;
}

img.slide-arrow {
  border: none;
  max-height: 37px;
  width: auto;
}

.hotspot {
  stroke: white;
  stroke-width: 3;
  fill: #ff9933;
  fill-opacity: 0.25;
  cursor: pointer;
}

.hotspot:hover {
  fill: #b35900;
}

/* added by Don for slides */
.slide-container {
  background-color: #fff;
  left: 0;
  position: absolute;
  width: 100%;
  touch-action: pan-y;
}

.slide-caption {
  border-top: 6px solid #eee;
  padding:  0.5em 2em;
  min-height: 300px;
}

.credits {
  text-align: left;
}

.credits p {
    margin: 0.8 0 0 0;
    margin-block-end: 0.2em;
    line-height: 1.2
}

.credits ul {
  /* margin: 2 0 0.8 0; */
  padding-top: 0em;
  list-style-type: disc; 
  list-style-position: inside; 
}
.credits li {
  line-height: 1.2;
}
